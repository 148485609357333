<style scoped lang="less">
  .resume-index {
    display: flex;
    padding: 20px;
    box-sizing: border-box;
  }
  .list {
    width: 250px;
    position: relative;
  }
  .right {
    flex: 1;
    margin-left: 20px;
    position: relative;
  }
  .worker-list {
    background-color: #FFF;
    position: relative;
    padding: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: auto;
    height: 100%;
    .worker-item {
      cursor: pointer;
      width: 33.3333%;
      padding: 8px;
      box-sizing: border-box;
    }
  }
</style>

<template>
  <div class="resume-index">
    <div class="list">
      <r-card style="height: 100%;" flex>
        <span slot="title">全部机构</span>
        <items @choose="orgChoose" :data="orgList" placeholder="请输入机构名称" />
      </r-card>
    </div>
    <div class="right">
      <r-card style="height: 100%;" flex>
        <span slot="title">{{chooseOrg ? chooseOrg.label : '全部机构'}}</span>
        <fm-input-new v-model="workerKey" clearable style="margin-right: 10px;" placeholder="员工搜索" slot="action">
          <i class="fmico fmico-search" slot="prefix"></i>
        </fm-input-new>
        <div class="worker-list">
          <router-link tag="div" :to="{
            name: 'resume.details.base',
            query: { id: worker.id }
          }" class="worker-item" v-for="worker in workerShowList" :key="worker.id">
            <worker :data="worker" />
          </router-link>
        </div>
      </r-card>
    </div>
  </div>
</template>

<script>
import RCard from '@/views/resume/components/card'
import Items from '@/views/resume/components/items'
import Worker from '@/views/resume/components/worker'

import { getWorkerList } from '@/views/resume/libs'

import { orgRequest } from '@/api'

// function treeFind (tree, fn) {
//   for (const v of tree) {
//     if (fn(v)) {
//       return v
//     } else if (Array.isArray(v.child) && v.child.length) {
//       const r = treeFind(v.child, fn)
//       if (r) {
//         return r
//       }
//     }
//   }
// }

// function treeFlat (tree) {
//   let items = []
//   for (const v of tree) {
//     items.push(v)
//     if (Array.isArray(v.child) && v.child.length) {
//       items = [...items, ...treeFlat(v.child)]
//     }
//   }
//   return items
// }

export default {
  components: { RCard, Items, Worker },
  data () {
    return {
      chooseOrg: null,
      workerKey: null,
      workerList: [],
      orgTree: [],
      // treeIdList: []
    }
  },
  computed: {
    orgList () {
      return [
        { label: '全部机构', key: null, data: null },
        ...this.$store.getters.orgList
      ]
    },
    workerShowList () {
      console.log(this.chooseOrg, 'co')
      // const treeIdList = this.treeIdList
      let orgTreeCode = this.chooseOrg && this.chooseOrg.data && this.chooseOrg.data.treeCode ? this.chooseOrg.data.treeCode : null
      if (this.workerKey || orgTreeCode) {
        return this.workerList.filter(v => {
          console.log(v)
          if (this.workerKey) {
            return v.name && v.name.indexOf(this.workerKey) > -1
          }
          if (orgTreeCode) {
            return v.positionTreeCode && v.positionTreeCode.indexOf(orgTreeCode) === 0
          }
        })
      }
      return this.workerList
    }
  },
  methods: {
    orgChoose (org) {
      this.chooseOrg = org
      // if (org.key) {
      //   const tree = treeFind(this.orgTree, (v) => v.id === org.key)
      //   this.treeIdList = treeFlat([tree]).map(v => v.positions || []).flat().map(v => v.id)
      // } else {
      //   this.treeIdList = []
      // }
      this.workerKey = null
    },
    async loadWorkerList () {
      this.workerList = await getWorkerList(true)
    },
    async loadOrgTree () {
      this.orgTree = await orgRequest.getTree()
    }
  },
  async mounted () {
    this.loadOrgTree()
    this.loadWorkerList()
    this.$store.dispatch('loadPositionList')
    this.$store.dispatch('loadOrgList')
  }
}
</script>
