/*
 * Author: hackerwand
 * Email: heipi@hackerwand.com
 * Date: Thu Jan 20 2022
 */
import { fileRequest, workerRequest, hisWorkRequest, workerEduRequest } from '@/api'

export async function getWorkerAvatar (id, size) {
    return new Promise(async resolve => {
        try {
            const response = await fileRequest.download({ id, size })
            resolve(window.URL.createObjectURL(response))
        } catch (error) {
            resolve(null)
        }
    })
}

let workDataCache = {}
export async function getWorkData (id, refresh = false) {
    if (refresh || !workDataCache[id]) {
        workDataCache[id] = hisWorkRequest.get({ workerId: id })
    }

    return await workDataCache[id]
}

let workerEduCache = {}
export async function getEduData (id, refresh = false) {
    if (refresh || !workerEduCache[id]) {
        workerEduCache[id] = workerEduRequest.get({ workerId: id })
    }

    return await workerEduCache[id]
}

let workerListCache = null
export async function getWorkerList (refresh = false) {
    if (refresh || !workerListCache) {
        workerListCache = new Promise(async resolve => {
            let res = await workerRequest.get()
            let list1 = res.filter(v => v.position !== null)
            let List2 = res.filter(v => v.position === null)
            list1.sort((a, b) => Number(a.position) - Number(b.position))
            res = list1.concat(List2)
            resolve(res)
        })
    }

    return await workerListCache
}

const workerInfoCache = {}
export async function getWorkerInfo (id, refresh = false) {
    if (refresh || !workerInfoCache[id]) {
        workerInfoCache[id] = new Promise(async resolve => {
            try {
                const data = await workerRequest.get({ id: id })
                if (data.length > 0) {
                    resolve(data[0])
                } else {
                    resolve(null)
                }
            } catch (error) {
                resolve(null)
            }
        })
    }
    return await workerInfoCache[id]
}