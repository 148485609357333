<style scoped lang="less">
  .resume-worker {
    background: #FAFAFA;
    padding: 15px;
    box-sizing: border-box;
  }
  .top {
    display: flex;
  }
  img.avatar {
    background-color: #FFF;
    width: 119px;
    height: 128px;
    object-fit: contain;
  }
  .info {
    flex: 1;
    margin-left: 25px;
  }
  .name {
    font-size: 16px;
    color: #000;
    padding-top: 8px;
  }
  .cells {
    margin-top: 18px;
  }
  .item {
    & + .item {
      margin-top: 15px;
    }
  }
  .iconfont {
    margin-right: 10px;
  }
  .field {
    color: rgba(0, 0, 0, .7);
  }
  .value {
    color: #000;
  } 
  .bottom {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
</style>

<template>
  <div class="resume-worker">
    <div class="top">
      <img :src="src" class="avatar" />
      <div class="info">
        <div class="name">{{data.name}}</div>
        <div class="cells">
           <div class="item">
            <i class="iconfont icon-yonghu1 theme-text-color"></i>
            <span class="field">职务：</span>
            <span class="value">{{positionText || '-'}}</span>
          </div>
           <div class="item">
            <i class="iconfont icon-gongshi theme-text-color"></i>
            <span class="field">工龄：</span>
            <span class="value">{{data.maritalStatus || '-'}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div>
        <span class="field">是否在编：</span>
        <span class="value">{{data.type || '-'}}</span>
      </div>
      <div>
        <span class="field">在岗状态：</span>
        <span class="value">{{data.jobTypeC || '-'}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getWorkerAvatar } from '@/views/resume/libs'

export default {
  props: {
    data: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      src: null,
      loading: {
        file: false
      }
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.addLazyLoad()
        this.lazyLoad()
      }
    }
  },
  computed: {
    positionList () {
      return this.$store.getters.positionList
    },
    positionMap () {
      let data = {}
      this.positionList.forEach(v => data[v.key] = v.label)
      return data
    },
    positionText () {
      return this.positionMap[this.data.positionId] || null
    }
  },
  methods: {
    async loadFile () {
      this.loading.file = true
      this.src = this.data.headFileId ? await getWorkerAvatar(this.data.headFileId, 'small') : null
      this.loading.file = false
    },
    lazyLoad () {
      const wHeight = window.innerHeight
      const margin = 100
      const { top } = this.$el.getBoundingClientRect()

      if (top > -margin && top < (wHeight + margin)) {
        this.loadFile()
        this.rmeoveLazyLoad()
      }
    },
    addLazyLoad () {
      this.$el.offsetParent.addEventListener('scroll', this.lazyLoad)
    },
    rmeoveLazyLoad () {
      if (this.$el.offsetParent) {
        this.$el.offsetParent.removeEventListener('scroll', this.lazyLoad)
      }
    }
  },
  destroyed () {
    this.rmeoveLazyLoad()
  },
  mounted () {
    this.addLazyLoad()
    this.lazyLoad()
  }
}
</script>
